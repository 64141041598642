<template>
  <div class="home" v-if="page[userInfo.type]">
    <template v-if="this.$device.windows">
      <div>
        <component
          v-for="component in page[userInfo.type].components"
          :key="component"
          :is="component"
          :userInfo="userInfo"
        />
        <!-- <p8 :userInfo="userInfo" /> -->
      </div>
    </template>
    <template v-else>
      <div>
        <component
          v-for="component in page[userInfo.type].components"
          :key="component"
          :is="component"
          :userInfo="userInfo"
        />
        <!-- <p8 :userInfo="userInfo" /> -->
      </div>
    </template>
  </div>
  <div v-else class="loader-container">
    <div class="loader"></div>
  </div>
</template>
<script>
import Api from "../services";
import p1 from "../components/p1";
import p2 from "../components/p2";
import p3 from "../components/p3";
import p4 from "../components/p4";
import p5 from "../components/p5";
import p6 from "../components/p6";
import p7 from "../components/p7";
import p8 from "../components/p8";

export default {
  components: {
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p7,
    p8,
  },
  data: () => ({
    userInfo: {
      name: "",
    },
    page: {
      cacung: {
        components: ["p1", "p4", "p5", "p6", "p7", "p8"],
        type: "banhanguytin",
      },
      banhanguytin: {
        components: ["p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8"],
        type: "banhanguytin",
      },
      chuyencan: {
        components: ["p1", "p4", "p3", "p5", "p6", "p7", "p8"],
        type: "banhanguytin",
      },
      daigiadataha: {
        components: ["p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8"],
        type: "banhanguytin",
      },
      duhanhgia: {
        components: ["p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8"],
        type: "banhanguytin",
      },
      thanhsanma: {
        components: ["p1", "p2", "p3", "p4", "p5", "p7", "p8"],
        type: "banhanguytin",
      },
    },
  }),
  methods: {
    setUpdata(data) {
      let segList = {
        other: "cacung",
        prepaid: "daigiadataha",
        donxa: "duhanhgia",
        promo: "thanhsanma",
        supplier_rating: "banhanguytin",
        active_day: "chuyencan",
      };
      let user = {
        id: data["user_id"],
        name: this.userInfo.name,
        type: segList[data.seg],
        activeDay: data["active_day"],
        stopPoint: data["stps"],
        ammount: data["amount"],
        distance: data["distance"],
        rank: data["rank"],
        totalValue: data["cod"],
        driverNum: data["unique_supply"],
        discountRate: data["discount_rate"],
      };
      // user = {
      //   name: "Vu",
      //   type: "duhanhgia",
      //   activeDay: 365,
      //   stopPoint: 500,
      //   ammount: 456,
      //   distance: 5000,
      //   rank: 45,
      //   totalValue: 150000000,
      //   driverNum: 6000,
      //   discountRate: 62.8,
      // };
      this.userInfo = user;
    },
    getUser(token) {
      return new Promise((resolve, reject) => {
        Api.getUserInfo(token)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            this.goNotFound();
          });
      });
    },
    getData(userId) {
      return new Promise((resolve, reject) => {
        Api.getData(userId)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            this.goNotFound();
          });
      });
    },
    async set() {
      let user = await this.getUser(this.$route.query.token);
      let data = await this.getData(user.data.user_id);
      if (!data[0]) {
        this.goNotFound()
        return;
      }
      this.setUpdata(data[0]);
    },
    goNotFound() {
      this.$router.push({
        name: "Notfound",
      });
    },
  },
  created() {
    this.set();
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  border: 1vw solid #f3f3f3; /* Light grey */
  border-top: 1vw solid #3498db; /* Blue */
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
