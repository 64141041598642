<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/2.png`)"
        />
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="desktop__inner one-line d-banhanguytin"
          :style="desktopStyle"
        >
          <Num :value="pvalue" /> /
          <div class="small">5<i class="star"></i></div>
        </div>
        <div
          v-else-if="userInfo.type == 'thanhsanma'"
          class="desktop__inner one-line"
          :style="desktopStyle"
        >
          <Num :value="pvalue" />
          <div>%</div>
        </div>
        <div v-else class="desktop__inner one-line" :style="desktopStyle">
          <Num :value="pvalue" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/2.png`)"
        />
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="mobile__inner one-line m-banhanguytin"
          :style="mobileStyle"
        >
          <Num :value="pvalue" /> /
          <div class="small">5<i class="star"></i></div>
        </div>

        <div
          v-else-if="userInfo.type == 'thanhsanma'"
          class="mobile__inner one-line"
          :style="mobileStyle"
        >
          <Num :value="pvalue" />
          <div>%</div>
        </div>
        <div v-else class="mobile__inner one-line" :style="mobileStyle">
          <Num :value="pvalue" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    pvalue() {
      let text = "";
      switch (this.userInfo.type) {
        case "banhanguytin":
          text = this.userInfo.ammount;
          break;
        case "daigiadataha":
          text = this.userInfo.ammount;
          break;
        case "duhanhgia":
          text = this.userInfo.ammount;
          break;
        case "thanhsanma":
          text = this.userInfo.discountRate + "%";
          break;
      }
      return text;
    },
    desktopStyle() {
      let style = "";
      switch (this.userInfo.type) {
        case "banhanguytin":
          style = {
            top: "25%",
            left: "25%",
            "font-size": "15vw",
          };
          break;
        case "daigiadataha":
          style = {
            top: "45%",
            left: "24%",
            "font-size": "5vw",
          };
          break;
        case "duhanhgia":
          style = {
            top: "46.5%",
            left: "22%",
            "font-size": "10vw",
          };
          break;
        case "thanhsanma":
          style = {
            top: "50%",
            left: "25%",
            "font-size": "10vw",
          };
          break;
      }
      return style;
    },
    mobileStyle() {
      let style = "";
      switch (this.userInfo.type) {
        case "banhanguytin":
          style = {
            top: "15%",
            left: "33%",
            "font-size": "20vw",
          };
          break;
        case "daigiadataha":
          style = {
            top: "30%",
            left: "45%",
            "font-size": "10vw",
          };
          break;
        case "duhanhgia":
          style = {
            top: "80%",
            left: "40%",
            "font-size": "15vw",
          };
          break;
        case "thanhsanma":
          style = {
            top: "75%",
            left: "60%",
            "font-size": "20vw",
          };
          break;
      }
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .one-line {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .small {
    font-size: 8vw;
    margin-left: 10px;
  }
  .star {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 1.2em;
    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 0.3em solid transparent;
    font-size: 4vw;
    &:before,
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0.6em;
      left: -1em;
      border-right: 1em solid transparent;
      border-bottom: 0.7em solid #fc0;
      border-left: 1em solid transparent;
      transform: rotate(-35deg);
    }
    &:after {
      transform: rotate(35deg);
    }
  }
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .d-banhanguytin {
      top: 0% !important;
      left: 50% !important;
    }
    .desktop__inner {
      position: absolute;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .one-line {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .m-banhanguytin {
      top: 15% !important;
      left: 50% !important;
    }
    .mobile__inner {
      position: absolute;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}
</style>