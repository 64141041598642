import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { isMobile } from 'mobile-device-detect';
import './assets/common/common.scss'
import { ObserveVisibility } from 'vue-observe-visibility'
import Num from "./components/common/num.vue"
import Pic from "./components/common/pic.vue"

Vue.directive('observe-visibility', ObserveVisibility)

Vue.config.productionTip = false

Vue.component('Num', Num)
Vue.component('Pic', Pic)
Vue.prototype.$device = {
  mobile: isMobile,
  windows: !isMobile,
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
