<template>
  <div
    class="page-wrapper"
    v-observe-visibility="{ callback: isViewableNow, once: true }"
  >
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/1.png`)"
        />
        <zoom-center-transition>
          <div class="page__inner" v-show="showName">
            {{ userInfo.id }}
          </div>
        </zoom-center-transition>
        <zoom-center-transition>
          <div class="title-image-desktop" v-show="showTitle">
            <img
              class="image"
              :src="require(`../assets/title_text/${userInfo.type}.png`)"
            />
          </div>
        </zoom-center-transition>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container-top">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/1.png`)"
        />
        <zoom-center-transition>
          <div class="page__inner" v-show="showName">
            {{ userInfo.id }}
          </div>
        </zoom-center-transition>
      </div>
      <div class="mobile-container-bottom">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/1-2.png`)"
        />
        <div class="page__inner">
          <zoom-center-transition>
            <img
              v-show="showTitle"
              class="title-image"
              :src="require(`../assets/title_text/${userInfo.type}.png`)"
            />
          </zoom-center-transition>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    ZoomCenterTransition,
  },
  data() {
    return {
      show: false,
      showName: false,
      showTitle: false,
    };
  },
  methods: {
    isViewableNow() {
      setTimeout(() => {
        this.showName = true;
        setTimeout(() => {
          this.showTitle = true;
        }, 500);
      }, 1000);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showName = true;
      setTimeout(() => {
        this.showTitle = true;
      }, 500);
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .page__inner {
      position: absolute;
      top: 8%;
      left: 20%;
      flex-direction: column;
      align-items: center;
      color: #ff8200;
      font-size: 5vw;
    }
    .title-image-desktop {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 50%;
      }
    }
  }
  .mobile-container-top {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .page__inner {
      position: absolute;
      top: 25%;
      left: 43%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      align-items: center;
      color: #ff8200;
      font-size: 5vw;
    }
  }
  .mobile-container-bottom {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .page__inner {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      align-items: center;
      width: 100%;
      .title-image {
        width: 70%;
      }
    }
  }
}
</style>