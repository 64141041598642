import axios from "axios";

const appDataApiInternal = axios.create({
  baseURL: `${process.env.VUE_APP_DATA_API}`,
  timeout: 90000,
});

const appUserApiInternal = axios.create({
  baseURL: `${process.env.VUE_APP_USER_API}`,
  timeout: 90000,
});
export default {
  async getUserInfo(token) {
    let data = await appUserApiInternal.post("", {
      token: token,
    }).then();
    return data;
  },
  async getData(userId) {
    let { data } = await appDataApiInternal.get("", {
      params: {
        cardid: 23347,
        user_id: userId,
      },
    }).then();
    return data;
  },
};
