<template>
  <div v-observe-visibility="{ callback: isViewableNow, once: false }">
    <zoom-center-transition :duration="200">
      <img
        v-show="show"
        class="wow"
        v-bind:style="imgStyle"
        :src="require(`../../assets/title_text/${image}.png`)"
      />
    </zoom-center-transition>
  </div>
</template>
<style scoped>
</style>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    imgStyle: {
      type: Object,
    },
  },
  components: {
    ZoomCenterTransition,
  },
  data() {
    return {
      show: false,
      showAnimation: false,
    };
  },
  methods: {
    isViewableNow(isVisible) {
      if (isVisible) {
        setTimeout(() => {
          this.show = isVisible;
        }, 4500);
      } else {
        this.show = isVisible;
      }
    },
  },
};
</script>