<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/3.png`)"
        />
        <div class="desktop__inner" :style="desktopStyle">
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="desktop__inner desktop__banhanguytin-1"
        >
          <div class="text">5</div>
        </div>
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="desktop__inner desktop__banhanguytin-2"
        >
          <div class="text">1</div>
        </div>
        <div
          v-if="userInfo.type == 'chuyencan'"
          class="desktop__inner desktop__chuyencan"
        >
          <div class="text">8%</div>
        </div>
        <div
          v-if="userInfo.type == 'daigiadataha'"
          class="desktop__inner desktop__daigia"
        >
          <div class="text one-line">
            <Num :value="userInfo.rank" />/<span class="small left-spacing">2000</span>
          </div>
        </div>
        <div
          v-if="userInfo.type == 'duhanhgia'"
          class="desktop__inner desktop__duhanhgia"
        >
          <div class="text one-line">2%</div>
        </div>
        <div
          v-if="userInfo.type == 'thanhsanma'"
          class="desktop__inner desktop__thanhsanma"
        >
          <div class="text one-line"><Num :value="13" />%</div>
        </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/3.png`)"
        />
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="mobile__inner mobile__banhanguytin-1"
        >
          <div class="text"><Num :value="5" /></div>
        </div>
        <div
          v-if="userInfo.type == 'banhanguytin'"
          class="mobile__inner mobile__banhanguytin-2"
        >
          <div class="text"><Num :value="1" /></div>
        </div>
        <div
          v-if="userInfo.type == 'chuyencan'"
          class="mobile__inner mobile__chuyencan"
        >
          <div class="text one-line"><Num :value="8" />%</div>
        </div>
        <div
          v-if="userInfo.type == 'daigiadataha'"
          class="mobile__inner mobile__daigia"
        >
          <div class="text one-line">
            <Num :value="userInfo.rank" /><span class="small">/2000</span>
          </div>
        </div>
        <div
          v-if="userInfo.type == 'duhanhgia'"
          class="mobile__inner mobile__duhanhgia"
        >
          <div class="text one-line"><Num :value="2" />%</div>
        </div>
        <div
          v-if="userInfo.type == 'thanhsanma'"
          class="mobile__inner mobile__thanhsanma"
        >
          <div class="text one-line"><Num :value="13" />%</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    desktopStyle() {
      let style = "";
      switch (this.userInfo.type) {
        case "banhanguytin":
          style = {
            top: "25%",
            left: "25%",
            "font-size": "15vw",
          };
          break;
        case "daigiadataha":
          style = {
            top: "45%",
            left: "25%",
            "font-size": "6vw",
          };
          break;
        case "duhanhgia":
          style = {
            top: "45%",
            left: "20%",
            "font-size": "10vw",
          };
          break;
        case "thanhsanma":
          style = {
            top: "50%",
            left: "25%",
            "font-size": "10vw",
          };
          break;
      }
      return style;
    },
    mobileStyle() {
      let style = "";
      switch (this.userInfo.type) {
        case "banhanguytin":
          style = {
            top: "15%",
            left: "33%",
            "font-size": "20vw",
          };
          break;
        case "daigiadataha":
          style = {
            top: "30%",
            left: "45%",
            "font-size": "10vw",
          };
          break;
        case "duhanhgia":
          style = {
            top: "80%",
            left: "40%",
            "font-size": "15vw",
          };
          break;
        case "thanhsanma":
          style = {
            top: "75%",
            left: "60%",
            "font-size": "20vw",
          };
          break;
      }
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .one-line {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .left-spacing {
    margin-left: 10px;
  }
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .desktop {
      &__inner {
        width: 100%;
      }
      &__banhanguytin-1 {
        @include overlayChildren(10%, 15%);
        font-size: 11vw;
      }
      &__banhanguytin-2 {
        @include overlayChildren(17%, 38%);
        font-size: 12vw;
      }
      &__chuyencan {
        @include overlayChildren(15%, 22%);
        font-size: 12vw;
      }
      &__daigia {
        @include overlayChildren(14%, 29%);
        font-size: 7vw;
        .small {
          font-size: 5vw;
        }
      }
      &__duhanhgia {
        @include overlayChildren(17%, 20%);
        font-size: 14vw;
      }
      &__thanhsanma {
        @include overlayChildren(17%, 20%);
        font-size: 12vw;
      }
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .mobile {
      &__inner {
        width: 100%;
      }
      &__banhanguytin-1 {
        @include overlayChildren(7%, 29%);
        font-size: 20vw;
      }
      &__banhanguytin-2 {
        @include overlayChildren(17%, 52%);
        font-size: 20vw;
      }
      &__chuyencan {
        @include overlayChildren(10%, 28%);
        font-size: 18vw;
      }
      &__daigia {
        @include overlayChildren(16%, 45%);
        font-size: 17vw;
        .small {
          font-size: 10vw;
        }
      }
      &__duhanhgia {
        @include overlayChildren(11%, 35%);
        font-size: 20vw;
      }
      &__thanhsanma {
        @include overlayChildren(13%, 35%);
        font-size: 20vw;
      }
    }
  }
}
</style>