<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/5.png`)"
        />
        <div class="desktop__inner-top">
          <div class="text"><Num :value="userInfo.stopPoint" /></div>
        </div>
        <div class="desktop__inner-middle">
          <div class="text"><Num :value="userInfo.distance" /></div>
        </div>
        <div class="desktop__inner-bottom">
          <div class="text"><Num :value="userInfo.totalValue" /></div>
        </div>
        <div class="desktop__inner-wow">
          <Pic :image="'wow'" class="image" :imgStyle="wowStyleDesktop" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/5.png`)"
        />
        <div class="mobile__inner-top">
          <div class="text"><Num :value="userInfo.stopPoint" /></div>
        </div>
        <div class="mobile__inner-middle">
          <div class="text"><Num :value="userInfo.distance" /></div>
        </div>
        <div class="mobile__inner-bottom">
          <div class="text"><Num :value="userInfo.totalValue" /></div>
        </div>
        <div class="mobile__inner-wow">
          <Pic :image="'wow'" class="image" :imgStyle="wowStyleMobile" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import pic from './common/pic.vue';
export default {
  components: { pic },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    wowStyleMobile() {
      return {
        width: "50%"
      }
    },
    wowStyleDesktop() {
      return {
        width: "30%"
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .desktop {
      &__inner-top {
        @include overlayChildren(53%, 20%);
        width: 100%;
        font-size: 9vw;
      }
      &__inner-middle {
        @include overlayChildren(65%, 58%);
        width: 100%;
        font-size: 9vw;
      }
      &__inner-bottom {
        @include overlayChildren(89%, 62%);
        width: 100%;
        font-size: 8vw;
      }
      &__inner-wow {
        @include overlayChildren(70%, 20%);
        width: 100%;
        font-size: 8vw;
      }
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .mobile {
      &__inner-top {
        @include overlayChildren(41%, 29%);
        width: 100%;
        font-size: 11vw;
      }
      &__inner-middle {
        @include overlayChildren(54%, 43%);
        width: 100%;
        font-size: 13vw;
      }
      &__inner-bottom {
        @include overlayChildren(94%, 45%);
        width: 100%;
        font-size: 10vw;
      }
      &__inner-wow {
        @include overlayChildren(70%, 30%);
        width: 100%;
        font-size: 8vw;
      }
    }
  }
}
</style>