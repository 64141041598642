<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/4.png`)"
        />
        <div
          class="desktop__inner"
          :class="{ chuyencan__desktop: userInfo.type == 'chuyencan' }"
        >
          <div class="text"><Num :value="userInfo.activeDay" /></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/4.png`)"
        />
        <div
          class="mobile__inner"
          :class="{ chuyencan__mobile: userInfo.type == 'chuyencan' }"
        >
          <div class="text"><Num :value="userInfo.activeDay" /></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  .chuyencan {
    &__desktop {
      top: 47%!important;
      left: 33%!important;
    }
    &__mobile {
      top: 35%!important;
      left: 35%!important;
    }
  }
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .desktop__inner {
      position: absolute;
      top: 35%;
      left: 38%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      align-items: center;
      width: 100%;
      .text {
        width: 70%;
        font-size: 8vw;
      }
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .mobile__inner {
      position: absolute;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      align-items: center;
      width: 100%;
      .text {
        width: 70%;
        font-size: 10vw;
      }
    }
  }
}
</style>