<template>
  <div v-observe-visibility="{ callback: isViewableNow, once: false }">
    {{ animatedNumber }}
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      number: 0,
      tweenedNumber: 0,
      count: 0,
    };
  },
  computed: {
    animatedNumber() {
      return this.numberWithCommas(this.tweenedNumber.toFixed(0));
    },
  },
  methods: {
    numberWithCommas(x) {
      return parseInt(x).toLocaleString();
    },
    isViewableNow(isVisible) {
      this.count += 1;
      if (this.count == 1) {
        return;
      }
      if (isVisible) {
        setTimeout(() => {
          this.number = this.value;
        }, 500);
      } else {
        this.number = 0;
      }
    },
  },
  watch: {
    number(newValue) {
      gsap.to(this.$data, { duration: newValue > 15 ? 1.5 : 0.5, tweenedNumber: parseInt(newValue) });
    },
  },
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
