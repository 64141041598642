<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/8.png`)"
        />
        <div class="desktop__inner-1">
          <div class="text"><Num :value="userInfo.stopPoint" /></div>
        </div>
        <div class="desktop__inner-2">
          <div class="text"><Num :value="userInfo.totalValue" /></div>
        </div>
        <div class="desktop__inner-3">
          <div class="text"><Num :value="userInfo.activeDay" /></div>
        </div>
        <div class="desktop__inner-4">
          <div class="text one-line"><Num :value="userInfo.discountRate" />%</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/8.png`)"
        />
        <div class="mobile__inner-1">
          <div class="text"><Num :value="userInfo.stopPoint" /></div>
        </div>
        <div class="mobile__inner-2">
          <div class="text"><Num :value="userInfo.totalValue" /></div>
        </div>
        <div class="mobile__inner-3">
          <div class="text"><Num :value="userInfo.activeDay" /></div>
        </div>
        <div class="mobile__inner-4">
          <div class="text one-line"><Num :value="userInfo.discountRate" />%</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .one-line {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .desktop {
      &__inner-1 {
        @include overlayChildren(44%, 70%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-2 {
        @include overlayChildren(50.5%, 84%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-3 {
        @include overlayChildren(57%, 75%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-4 {
        @include overlayChildren(63%, 79%);
        width: 100%;
        font-size: 4vw;
      }
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .mobile {
      &__inner-1 {
        @include overlayChildren(76.5%, 50%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-2 {
        @include overlayChildren(81%, 72%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-3 {
        @include overlayChildren(85.6%, 55%);
        width: 100%;
        font-size: 4vw;
      }
      &__inner-4 {
        @include overlayChildren(90.5%, 67%);
        width: 100%;
        font-size: 4vw;
      }
    }
  }
}
</style>