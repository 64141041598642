<template>
  <div class="page-wrapper">
    <template v-if="this.$device.windows">
      <div class="desktop-container">
        <img
          class="image-background"
          :src="require(`../assets/desktop/${userInfo.type}/6.png`)"
        />
        <div class="desktop__inner-top">
          <div class="text one-line"><Num :value="userInfo.discountRate" />%</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-container">
        <img
          class="image-background"
          :src="require(`../assets/mobile/${userInfo.type}/6.png`)"
        />
        <div class="mobile__inner-top">
          <div class="text one-line"><Num :value="userInfo.discountRate" />%</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin overlayChildren($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
.page-wrapper {
  width: 100%;
  .one-line {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktop-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .desktop {
      &__inner-top {
        @include overlayChildren(57%, 67%);
        width: 100%;
        font-size: 9vw;
      }
    }
  }
  .mobile-container {
    position: relative;
    text-align: center;
    .image-background {
      width: 100%;
      max-width: 100%;
    }
    .mobile {
      &__inner-top {
        @include overlayChildren(72%, 24%);
        width: 100%;
        font-size: 13vw;
        font-family: gothamUltra;
      }
    }
  }
}
</style>